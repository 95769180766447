import React from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from './Button.module.scss';

const Button = ({ children, login, href, event, bold }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  if (event) {
    return (
      <div>
      </div>
    );
  }

  return (
    <div>
    </div>
  );
};

export default Button;